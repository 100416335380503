@import url('./static/fonts/blender-bold/style.css');
/* @import url('./fonts/blender-book/style.css'); */
@font-face {
  font-family: 'Blender Pro' !important; /* Define the custom font name */
  src: url('./static/fonts/blender-bold/BlenderPro-Bold.woff2') format('woff2'),
    url('./static/fonts/blender-book/BlenderPro-Book.woff') format('woff'); /* Define where the font can be downloaded */
  font-display: fallback; /* Define how the browser behaves during download */
}
/* html {
  background: #1e1a20;
} */

::-webkit-scrollbar {
  display: none;
}
:root {
  --color-icon: #535c68;
  --social-icon1: #6cc644;
  --social-icon2: #1da1f2;
  --social-icon3: #0077b5;
  --social-icon4: #c13584;
  --social-icon5: #e4405f;
}
/* YT Red 
#cd201f */

body {
  background-color: #000;
  /* background-color: transparent; */
  font-family: 'Blender Pro' !important;
  font-weight: 700;
  font-feature-settings: 'zero';
  font-variant-numeric: slashed-zero;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.section {
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  padding-left: 10%;
  padding-right: 10%;
  outline: none;
  will-change: transform;
  z-index: 11;
}
#app {
  outline: none;
  border: none;
  z-index: 20;
}
section:nth-child(odd) {
  justify-content: flex-end;
}
:-webkit-any(article, aside, nav, section) h1 {
  font-size: 72px;
  /* margin-block-start: 0.83em; */
  /* margin-block-end: 0.83em; */
}
/* main {
} */

.hero-container {
  outline: none;
  display: flex;
  height: 80vh;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  top: 100%;
  z-index: 4;
}

.animation-slide {
  animation: slideInFromBottom 2s cubic-bezier(0.16, 1, 0.3, 1) 1s;
  animation-fill-mode: forwards;
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    display: inline-block;
    transform: translateY(0);
    opacity: 1;
  }
}

.icon-list {
  width: 100%;
  max-width: 32rem;
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 11;
}

.icon-item {
  list-style: none;
}

.icon-link:hover {
  color: #fff;
}

.icon-link i {
  margin: auto;
}

.icon-link:hover::before {
  transform: scale(1);
}

.icon-item:nth-child(1) a:hover:before {
  background: var(--social-icon1);
}

.icon-item:nth-child(2) a:hover:before {
  background: var(--social-icon2);
}

.icon-item:nth-child(3) a:hover:before {
  background: var(--social-icon3);
}

.icon-item:nth-child(4) a:hover:before {
  background: var(--social-icon4);
}

.icon-item:nth-child(5) a:hover:before {
  background: var(--social-icon5);
}

canvas {
  position: fixed !important;
  top: 0;
  left: 0;
  touch-action: auto;
  outline: none;
  border: none;
  z-index: -5;
}
/* Media Query Affected Classes */
.hero-title {
  color: #fff;
  display: inline-block;
  font-size: 2.7rem;
  margin-bottom: 20rem;
  line-height: 1em;
  opacity: 0;
  width: auto;
}
.hero-subtitle {
  color: #fff;
  display: inline-block;
  font-size: 1.7rem;
  margin-bottom: 3rem;
  line-height: 1em;
  opacity: 0;
}
.title-underline {
  background-image: none;
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 0%;
}

.links-container {
  /* margin-left: 2.5rem; */
  width: 0%;
  height: 11vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 22;
}

.icon-link {
  display: inline-flex;
  font-size: 1.8rem;
  text-decoration: none;
padding-left: 0.7rem;
  color: var(--color-icon);
  width: 4.3rem;
  height: 6rem;
  transition: 0.5s linear;
  position: relative;
  z-index: 12;
  margin: auto;
}

.icon-link::before {
  content: '';
  margin-top: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 5rem;
  height: 5rem;
  background: #000;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0);
  transition: 0.3s cubic-bezier(0.95, 0.32, 0.37, 1.21);
}
@media screen and (min-width: 550px) {
  canvas {
    position: fixed !important;
    top: 0;
    left: 0;
    touch-action: auto;
    z-index: -5;
  }
  .hero-title {
    font-size: 4.063rem;
    margin-bottom: 16rem;
  }
  .hero-subtitle {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 0rem;
  }
  .icon-link {
    font-size: 2.3rem;
    padding-left: 0;
    width: 5.1rem;
    height: 6rem;
  }
   .icon-list {
    width: 100%;
    max-width: 50rem;
    padding: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 11;
  }
  .links-container {
    margin-left: 0;
    width: 100%;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 22;
  }
}
@media screen and (min-width: 854px) {
  canvas {
    position: fixed !important;
    top: 0;
    left: 0;
    touch-action: auto;
    z-index: -5;
  }
  .icon-list {
    width: 100%;
    max-width: 50rem;
    padding: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 11;
  }
  .icon-link::before {
    position: absolute;
    margin-top: 0rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 6rem;
    height: 6rem;
    background: #000;
    border-radius: 50%;
    z-index: -1;
    transform: scale(0);
    transition: 0.3s cubic-bezier(0.95, 0.32, 0.37, 1.21);
  }
  .icon-link {
    font-size: 3rem;
    padding-left: 0;
    width: 6rem;
    height: 6rem;
  }
  .links-container {
    margin-left: 0;
    width: 100%;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 22;
  }
  .hero-title {
    font-size: 4.688rem;
    line-height: 64px;
    margin-bottom: 0rem;
  }
  .hero-subtitle {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0rem;
  }
  .title-underline {
    background-image: linear-gradient(90deg, #000, #000);
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: 100% 50%;
  }
}
